<template>
  <div class="d-none">
    <b-modal v-model="showModal" centered>
      <template #modal-header="{ close }">
        <div class="modal-title">ยืนยันแลกของสมนาคุณ</div>
      </template>

      <div>
        ต้องการใช้ {{ product.point_used | numeral('0,0') }} คะแนน เพื่อแลก
        {{ product.product_name }} สำหรับคุณ {{ customer.first_name_th }}
        {{ customer.last_name_th }} โดยมารับที่สาขา
        {{ customer.branch_name }} หรือไม่?
      </div>

      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="confirm"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    product: {
      required: false,
      type: Object
    },
    customer: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId',
      userGuid: 'getUserGuid'
    })
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async confirm() {
      let body = {
        user_guid: this.customer.user_guid,
        redeem_id: this.product.redeem_id,
        product_id: this.product.product_id,
        redeem_type_id: this.product.product_type,
        branch_id: this.branchId,
        create_user_id: this.userGuid
      };

      await this.$store.dispatch('setMainLoading', true);
      await this.axios
        .post(`${this.$baseUrl}/redeem/redeem_item`, body)
        .then(data => {
          this.$store.dispatch('setMainLoading', false);
          if (data.result == 1) {
            this.$emit('success');
            this.hide();
          } else {
            
            this.warningAlert(data.detail.message);
          }
        });
    }
  }
};
</script>

<style scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}

::v-deep .modal-body {
  color: var(--primary-color);
  padding: 15px !important;
}
.btn-outline {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}

@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
  }
}
</style>
