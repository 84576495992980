<template>
  <div>
    <b-modal v-model="showModal" centered size="lg" hide-footer>
      <template #modal-header="{ close }">
        <div class="modal-title">แลกของสมนาคุณ สาขา {{ branchName }}</div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="data"
          show-empty
          empty-text="No matching records found"
          class="table-main"
          sticky-header
          :no-border-collapse="false"
        >
          <template v-slot:cell(select)="data">
            <b-button
              variant="primary-color"
              class="btn-modal btn-hover"
              @click="handleSelect(data.item)"
              :disabled="data.item.redeemable == 'N' ? true : false"
            >
              เลือก
            </b-button>
          </template>
          <template v-slot:cell(image_url)="data">
            <img
              v-if="data.item.image_url"
              :src="data.item.image_url"
              alt="product"
              width="100"
              class="mr-2"
            />
            <img
              v-else
              src="@/assets/images/noimg.png"
              alt="no-image-product"
              width="100"
              class="mr-2"
            />
          </template>
          <template v-slot:cell(product_name)="data">
            <p class="text-black">{{ data.item.product_name }}</p>
            <p>({{ data.item.redeem_name }}}</p>
            <p class="mt-3">
              {{ data.item.point_used | numeral('0,0') }} คะแนน
            </p>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
    <ModalConfirmRedeem
      :product="selectedProduct"
      :customer="customer"
      ref="modalConfirmRedeem"
      @success="confirmRedeem"
    />
  </div>
</template>

<script>
import ModalConfirmRedeem from './ModalConfirmRedeem';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalConfirmRedeem
  },
  props: {
    data: {
      required: false,
      type: Array
    },
    customer: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      showModal: false,
      selectedProduct: {},
      fields: [
        {
          key: 'select',
          label: ''
        },
        {
          key: 'image_url',
          label: ''
        },
        {
          key: 'product_name',
          label: 'ชื่อ / คะแนน'
        }
      ]
    };
  },
  computed: {
    ...mapGetters({ branchName: 'getBranchName' })
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    handleSelect(item) {
      this.selectedProduct = item;
      this.$refs.modalConfirmRedeem.show();
    },
    confirmRedeem() {
      this.$emit('success');
      this.hide();
    }
  }
};
</script>

<style scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.btn-outline {
  border-color: var(--primary-color) !important;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}
.text-wrap-customs {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em;
}
.product-list-panel {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.product-list-panel .title {
  position: sticky;
  top: -2px;
  z-index: 1;
}
::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  font-size: 16px;
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .b-table-sticky-header {
  max-height: calc(65vh - 46px);
}
</style>
